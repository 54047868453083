<template>
  <div style="display: flex;background: #000000;flex-direction: column;align-items: center;">

    <img src="../assets/ic_app_logo.png" width="80Px" style="border-radius: 10Px;margin-top: 60Px;">

    <span style="color:white;font-weight: bold;font-size: 18Px;margin-top: 10Px;">萌正太短视频</span>

    <a :href="h5Url" class="download-button" v-clipboard:copy="copyValue">iOS轻量版下载</a>

    <div style="font-size: 8px;color: white;margin-top: 5px">请使用苹果自带Safari浏览器打开</div>

    <div style="color: white;font-size: 12px;margin-top: 30px;margin-bottom: 10px;">1. 点击下载后弹窗选择"允许"</div>

    <img src="../assets/web1.jpg" width="220Px">

    <div style="color: white;font-size: 12px;margin-top: 30px;margin-bottom: 10px;">2. 到"系统设置"-"通用"，点击 [已下载描述文件]</div>

    <img src="../assets/web2.jpg" width="220Px">

    <div style="color: white;font-size: 12px;margin-top: 30px;margin-bottom: 10px;">3. 点击"安装"，输入锁屏密码即可安装</div>

    <img src="../assets/web3.jpg" width="220Px">

    <div style="height: 50Px"></div>

  </div>
</template>

<script>
export default {
  name: "DownLoadForWebClip",
  data() {
    return {
      h5Url: null,
      copyValue: "",
    }
  },
  methods: {

  },
  created() {
    if(this.$route.params.h5Url){
      this.h5Url = this.$route.params.h5Url;
    }
    if(this.$route.params.copyValue){
      this.copyValue = this.$route.params.copyValue;
    }
  },
  mounted: function () {

  }
}
</script>

<style>

.dot span {
  line-height: 50px;
  vertical-align: middle;
}

.download-button {
  width: 432px;
  height: 80px;
  border-radius: 45px;
  background: linear-gradient(#f63d58, #9929ea);
  font-family: PingFang SC;
  font-size: 30px;
  align-self: center;
  margin-top: 40px;
  color: white;
  text-align: center;
  line-height: 80px;
  font-weight: 500;
  vertical-align: middle;
}
</style>
